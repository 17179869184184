import LatLon from 'geodesy/latlon-ellipsoidal-vincenty.js'

export async function geneticAlgorithm(_combolocations) {
  //_combolocations is an array of combo names
  //Step 1: create populaton of N elements, each with randomly generated DNA
  //Step 2: evaluate the fitness of each element of the population
  //Step 3: repeat N times
  //        a: pick two parent with probability according to relative fitness
  //        b: crossover - create a child by combining DNA of both parents
  //        c: mutation - mutate the child's DNA based on a given probability
  //        d: add new child to population
  //Step 4: replace old population with new population and continue with step 2
  let genes = []
  let population = []
  let mutationrate = 0.02
  let count_population
  let bestsofar_fitness = 0
  let previousbest_fitness = 0
  let bestsofar_route = null
  let convergencemax = 2 * _combolocations.length
  let convergencecounter = convergencemax
  let populationmax = 20 * _combolocations.length

  //Step 1
  for (
    count_population = 0;
    count_population < populationmax;
    count_population++
  ) {
    let workcombolocation = _combolocations.slice()
    for (
      let count_genes = 0;
      count_genes < _combolocations.length;
      count_genes++
    ) {
      let index = Math.floor(Math.random() * workcombolocation.length)
      genes[count_genes] = workcombolocation[index]
      workcombolocation.splice(index, 1)
    }
    population.push(genes.slice())
  }

  //Step 2
  while (convergencecounter > 0) {
    let fitness = []
    for (
      count_population = 0;
      count_population < populationmax;
      count_population++
    ) {
      fitness[count_population] = geneticFitness(
        population[count_population],
        this
      )
    }
    const sumArray = (accumulator, currentValue) => accumulator + currentValue
    let fitnesstotal = fitness.reduce(sumArray)
    let probability = []
    for (let i = 0; i < fitness.length; i++) {
      probability[i] = fitness[i] / fitnesstotal
    }

    previousbest_fitness = bestsofar_fitness
    bestsofar_fitness = Math.floor(1000 / fitness[bestFitness(fitness)])
    bestsofar_route = population[bestFitness(fitness)].join()

    if (bestsofar_fitness === previousbest_fitness) {
      convergencecounter--
    } else {
      convergencecounter = convergencemax
    }

    //Step 3
    let workpopulation = []
    for (
      count_population = 0;
      count_population < populationmax;
      count_population++
    ) {
      let child = []
      let parentA = population[pickParent(probability)].slice()
      let parentB = population[pickParent(probability)].slice()
      let split = Math.floor(Math.random() * parentA.length)

      for (let childcount = 0; childcount < split; childcount++) {
        child[childcount] = parentA[childcount]
        let index = parentB.indexOf(parentA[childcount])
        parentB.splice(index, 1)
      }
      for (let childcount = split; childcount < parentA.length; childcount++) {
        child[childcount] = parentB[childcount - split]
      }

      for (let childcount = 0; childcount < child.length; childcount++) {
        if (Math.random() < mutationrate) {
          let index1 = Math.floor(Math.random() * child.length)
          let index2 = Math.floor(Math.random() * child.length)
          let temp = child[index1]
          child[index1] = child[index2]
          child[index2] = temp
        }
      }
      workpopulation.push(child.slice())
    }
    population = workpopulation.slice()
  }
  return { length: bestsofar_fitness, route: bestsofar_route }
}

function pickParent(_probability) {
  let index = 0
  let r = Math.random()
  while (r > 0) {
    r = r - _probability[index]
    index++
  }
  index--
  return index
}

function bestFitness(_fitness) {
  let sortP = _fitness.slice().sort((a, b) => (a > b ? 1 : -1))
  return _fitness.indexOf(sortP[sortP.length - 1])
}

function geneticFitness(_genes, _this) {
  let totaldistance = 0
  let locationA = null
  let locationB = null
  for (let count_genes = 1; count_genes < _genes.length; count_genes++) {
    locationA = _this.$store.getters[
      'moduleBonusLocations/BonusLocationsByNameGetter'
    ](_genes[count_genes - 1])
    locationB = _this.$store.getters[
      'moduleBonusLocations/BonusLocationsByNameGetter'
    ](_genes[count_genes])
    let distance =
      (new LatLon(locationA.latitude, locationA.longitude).distanceTo(
        new LatLon(locationB.latitude, locationB.longitude)
      ) /
        1609) *
      _this.$store.state.modulePreferences.Preferences.routing
        .vincenty_distance_adjustment

    totaldistance = totaldistance + distance
  }
  return 1000 / totaldistance
}
