import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Bonus clusters ("+_vm._s(_vm.Preferences ? _vm.Preferences.routing.cluster_proximity : 'n/a')+" mile radius)")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"pink","loading":_vm.loadingProximityButton},on:{"click":function($event){return _vm.recalculateProximity()}}},[_vm._v("Recalc Clusters")])],1),_c(VCardText,[_c(VDataTable,{attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.BonusLocationsWithProximityGetter,"item-key":"name","group-key":"proximity","group-by":"proximity","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
return [_c('th',{staticClass:"subtitle-2",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(group)+" - total "+_vm._s(Number(_vm.calculateGroupTotalPoints(group)).toLocaleString())+" points - "+_vm._s(_vm.calculateGroupCategory(group))+" "),_c(VBtn,{staticClass:"pl-5",attrs:{"icon":"","href":"https://maps.google.com","target":"_blank"}},[_c(VIcon,[_vm._v("mdi-google-maps")])],1)],1)]}},{key:"item.timezoneid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.timezoneShort)+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category !== undefined)?_c(VChip,{attrs:{"color":item.getSymbolcolor() + ' lighten-3',"dark":"","pill":"","small":""}},[_vm._v(_vm._s(item.category))]):_vm._e()]}}])})],1)],1),_c(VCard,{staticClass:"mt-10",attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Combos")])],1),_c(VCardText,[_c(VDataTable,{attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.BonusLocationsCombosConstituentsGetter,"item-key":"index","group-key":"comboname","group-by":"comboname","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
return [_c('th',{staticClass:"subtitle-2",attrs:{"colspan":headers.length - 1}},[_vm._v(" "+_vm._s(group)+" - "+_vm._s(Number( _vm.BonusLocationsByNameGetter(group).points ).toLocaleString())+" combo points - total "+_vm._s(Number( _vm.BonusLocationsByNameGetter(group).combototalpoints ).toLocaleString())+" points - "+_vm._s(_vm.BonusLocationsByNameGetter(group).value)+" - "+_vm._s(_vm.calculateComboGroupCategory(group))+" - "+_vm._s(Number( _vm.BonusLocationsByNameGetter(group).bestroutelength ).toLocaleString())+" miles - "+_vm._s(_vm.calculatePointsPerMile(group))+" points/mile ")]),_c('th',[_c(VBtn,{staticClass:"px-5",attrs:{"icon":""},on:{"click":function($event){return _vm.showComboMap(group)}}},[_c(VIcon,[_vm._v("mdi-google-maps")])],1),_c(VIcon,{on:{"click":function($event){return _vm.askDeleteCombo(group)}}},[_vm._v("mdi-delete")])],1)]}},{key:"item.timezoneid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.timezoneShort)+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category !== undefined)?_c(VChip,{attrs:{"color":item.getSymbolcolor() + ' lighten-3',"dark":"","pill":"","small":""}},[_vm._v(_vm._s(item.category))]):_vm._e()]}}])}),_c(VBtn,{attrs:{"color":"pink","dark":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":function($event){_vm.dialogAddCombo = true}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1),_c('dialogAddCombo',{model:{value:(_vm.dialogAddCombo),callback:function ($$v) {_vm.dialogAddCombo=$$v},expression:"dialogAddCombo"}}),_c('dialogShowCombo',{attrs:{"mapMarkers":_vm.mapMarkers},model:{value:(_vm.dialogShowCombo),callback:function ($$v) {_vm.dialogShowCombo=$$v},expression:"dialogShowCombo"}}),_c('dialogDelete',{attrs:{"objectid":_vm.objectidToBeDeleted},on:{"delete":_vm.dialogDeleteAction},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }