<template>
  <v-container>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title
          >Bonus clusters ({{
            Preferences ? Preferences.routing.cluster_proximity : 'n/a'
          }}
          mile radius)</v-toolbar-title
        ><v-spacer />
        <v-btn
          color="pink"
          :loading="loadingProximityButton"
          @click="recalculateProximity()"
          >Recalc Clusters</v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-data-table
          dense
          :headers="tableHeaders"
          :items="BonusLocationsWithProximityGetter"
          item-key="name"
          group-key="proximity"
          group-by="proximity"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:group.header="{ group, headers }">
            <th :colspan="headers.length" class="subtitle-2">
              {{ group }}
              - total
              {{ Number(calculateGroupTotalPoints(group)).toLocaleString() }}
              points - {{ calculateGroupCategory(group) }}
              <v-btn
                class="pl-5"
                icon
                href="https://maps.google.com"
                target="_blank"
              >
                <v-icon>mdi-google-maps</v-icon></v-btn
              >
            </th>
          </template>

          <template v-slot:item.timezoneid="{ item }">
            {{ item.timezoneShort }}
          </template>

          <template v-slot:item.category="{ item }">
            <v-chip
              v-if="item.category !== undefined"
              :color="item.getSymbolcolor() + ' lighten-3'"
              dark
              pill
              small
              >{{ item.category }}</v-chip
            >
          </template>
        </v-data-table>
      </v-card-text></v-card
    >
    <v-card elevation="2" class="mt-10">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Combos</v-toolbar-title>
      </v-toolbar>
      <v-card-text
        ><v-data-table
          dense
          :headers="tableHeaders"
          :items="BonusLocationsCombosConstituentsGetter"
          item-key="index"
          group-key="comboname"
          group-by="comboname"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:group.header="{ group, headers }">
            <th :colspan="headers.length - 1" class="subtitle-2">
              {{ group }}
              -
              {{
                Number(
                  BonusLocationsByNameGetter(group).points
                ).toLocaleString()
              }}
              combo points - total
              {{
                Number(
                  BonusLocationsByNameGetter(group).combototalpoints
                ).toLocaleString()
              }}
              points - {{ BonusLocationsByNameGetter(group).value }} -
              {{ calculateComboGroupCategory(group) }}
              -
              {{
                Number(
                  BonusLocationsByNameGetter(group).bestroutelength
                ).toLocaleString()
              }}
              miles - {{ calculatePointsPerMile(group) }} points/mile
            </th>
            <th>
              <v-btn class="px-5" icon @click="showComboMap(group)">
                <v-icon>mdi-google-maps</v-icon></v-btn
              >
              <v-icon @click="askDeleteCombo(group)">mdi-delete</v-icon>
            </th>
          </template>

          <template v-slot:item.timezoneid="{ item }">
            {{ item.timezoneShort }}
          </template>

          <template v-slot:item.category="{ item }">
            <v-chip
              v-if="item.category !== undefined"
              :color="item.getSymbolcolor() + ' lighten-3'"
              dark
              pill
              small
              >{{ item.category }}</v-chip
            >
          </template>
        </v-data-table>
        <v-btn
          color="pink"
          dark
          absolute
          bottom
          right
          fab
          @click="dialogAddCombo = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn></v-card-text
      ></v-card
    >
    <dialogAddCombo v-model="dialogAddCombo" />
    <dialogShowCombo v-model="dialogShowCombo" :mapMarkers="mapMarkers" />
    <dialogDelete
      v-model="showDeleteDialog"
      :objectid="objectidToBeDeleted"
      @delete="dialogDeleteAction"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import dialogAddCombo from '@/components/dialogs/dialogAddCombo'
import dialogShowCombo from '@/components/dialogs/dialogShowCombo'
import dialogDelete from '@/components/dialogs/dialogDelete'
import * as Luxon from 'luxon'
import { tsMethodSignature } from '@babel/types'
export default {
  name: 'Combos',
  components: { dialogAddCombo, dialogShowCombo, dialogDelete },
  data() {
    const data = {
      objectidToBeDeleted: '',
      showDeleteDialog: false,
      mapMarkers: [],
      dialogAddCombo: false,
      dialogShowCombo: false,
      loadingProximityButton: false,
      selectedCombo: {},
      tableHeaders: [
        { text: 'Location', align: 'start', sortable: true, value: 'name' },
        { text: 'Longname', sortable: true, value: 'longname' },
        { text: 'Points', sortable: true, value: 'points' },
        { text: 'Category', sortable: true, value: 'category' },
        { text: 'Timezone', sortable: false, value: 'timezoneid' },
        { text: 'Value', sortable: true, value: 'value' },
        { text: '', sortable: true, value: '' }
      ]
    }
    return { ...data, ...Luxon }
  },
  computed: {
    ...mapState({
      BonusLocations: (state) => state.moduleBonusLocations.BonusLocations,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Preferences: (state) => state.modulePreferences.Preferences
    }),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsWithProximityGetter',
      'BonusLocationsCombosConstituentsGetter',
      'BonusLocationsByNameGetter',
      'BonusLocationsRidingOnlyGetter'
    ])
  },
  methods: {
    ...mapActions('moduleBonusLocations', ['BonusLocationsDeleteAction']),
    async recalculateProximity() {
      this.loadingProximityButton = true
      for (let index in this.BonusLocationsRidingOnlyGetter) {
        await this.BonusLocationsRidingOnlyGetter[index].setProximity()
      }
      this.loadingProximityButton = false
    },
    async dialogDeleteAction(_event) {
      if (_event === 'combo') {
        const bonusToBeDeleted = this.BonusLocations.find(
          (bonus) => bonus.bonusid === this.objectidToBeDeleted
        )
        this.BonusLocationsDeleteAction({
          bonus: bonusToBeDeleted,
          rallyid: this.UserProfile.activerallyid
        })
      }
      this.objectidToBeDeleted = ''
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Combos
     * @param {BonusLocationsPrototype}
     * @returns {Void}
     */
    askDeleteCombo(_bonuslocation) {
      this.objectidToBeDeleted =
        this.BonusLocationsByNameGetter(_bonuslocation).bonusid
      this.showDeleteDialog = true
    },

    showComboMap(_selectedcombo) {
      this.mapMarkers = []
      for (let index in this.BonusLocationsCombosConstituentsGetter) {
        if (
          this.BonusLocationsCombosConstituentsGetter[index].comboname ===
          _selectedcombo
        ) {
          this.mapMarkers.push({
            position: {
              lat: Number(
                this.BonusLocationsByNameGetter(
                  this.BonusLocationsCombosConstituentsGetter[index].name
                ).latitude
              ),
              lng: Number(
                this.BonusLocationsByNameGetter(
                  this.BonusLocationsCombosConstituentsGetter[index].name
                ).longitude
              )
            },
            name: this.BonusLocationsCombosConstituentsGetter[index].name,
            icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
          })
        }
      }
      this.selectedCombo = _selectedcombo
      this.dialogShowCombo = true
    },
    deleteCombolocation(_bonusLocation) {
      confirm('Are you sure you want to delete this combo?') &&
        this.BonusLocationsDeleteAction({
          bonus: this.BonusLocationsByNameGetter(_bonusLocation),
          rallyid: this.UserProfile.activerallyid
        })
    },
    calculatePointsPerMile(_groupID) {
      return Number(
        this.BonusLocationsByNameGetter(_groupID).combototalpoints /
          this.BonusLocationsByNameGetter(_groupID).bestroutelength
      )
        .toFixed(0)
        .toLocaleString()
    },
    calculateGroupCategory(_groupID) {
      let groupCategory = 'all day'
      let result = this.BonusLocations.filter(
        (bonuslocation) =>
          bonuslocation.proximity !== undefined &&
          bonuslocation.proximity !== null
      )
      result = result.filter(
        (bonuslocation) => bonuslocation.proximity.toString() === _groupID
      )
      for (let item in result) {
        if ((result[item].category === 'D') & (groupCategory === 'all day')) {
          groupCategory = 'daylight'
        }
        if (result[item].category === 'T') {
          groupCategory = 'time restricted'
        }
      }
      return groupCategory
    },
    calculateComboGroupCategory(_groupID) {
      let category = this.BonusLocationsByNameGetter(_groupID).category
      switch (category) {
        case 'AC':
          return 'all day'
        case 'DC':
          return 'daylight'
        case 'TC':
          return 'time restricted'
        default:
          return 'all day'
      }
    },

    calculateGroupTotalPoints(_groupID) {
      let total = 0
      let result = this.BonusLocations.filter(
        (bonuslocation) =>
          bonuslocation.proximity !== undefined &&
          bonuslocation.proximity !== null
      )
      result = result.filter(
        (bonuslocation) => bonuslocation.proximity.toString() === _groupID
      )
      for (let item in result) {
        total = total + Number(result[item].points)
      }
      return total
    }
  }
}
</script>
