<template>
  <v-dialog v-model="value" max-width="1000px" @click:outside="close()">
    <v-card elevation="2">
      <v-form ref="formCombo" v-model="isValid">
        <v-toolbar flat color="blue" dark>
          <v-toolbar-title>Add combo bonus</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="3">
              <v-text-field
                v-model="newComboBonus.name"
                tabindex="1"
                autofocus
                label="Combo name"
                prepend-icon="mdi-group"
                dense
                :rules="[
                  validateRequired(newComboBonus.name),
                  validateHTML(newComboBonus.name),
                  validateLength(newComboBonus.name, 250),
                  validateCharacters(newComboBonus.name)
                ]"
                @change="updateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="newComboBonus.points"
                tabindex="1"
                label="Combo value"
                type="number"
                dense
                prepend-icon="mdi-counter"
                :rules="[
                  validateRequired(newComboBonus.points),
                  validateMinimum(newComboBonus.points, 0),
                  validateMaximum(newComboBonus.points, 250000)
                ]"
                @change="updateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="newComboBonus.combototalpoints"
                tabindex="-1"
                label="Total point value"
                type="number"
                dense
                readonly
                prepend-icon="mdi-sigma"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="newComboBonus.category"
                tabindex="-1"
                label="Combo category"
                type="text"
                dense
                readonly
                prepend-icon="mdi-shape"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="newComboBonus.description"
                tabindex="4"
                label="Description"
                prepend-icon="mdi-subtitles-outline"
                dense
                :rules="[
                  validateHTML(newComboBonus.description),
                  validateLength(newComboBonus.description, 1000),
                  validateCharacters(newComboBonus.description)
                ]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="6">
              <v-text-field
                v-model="newComboBonus.page"
                tabindex="5"
                label="Rallybook page"
                type="number"
                dense
                :rules="[
                  validateWholeNumber(newComboBonus.page),
                  validateMinimum(newComboBonus.page, 0),
                  validateMaximum(newComboBonus.page, 1000)
                ]"
                @change="updateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="newComboBonus.longname"
                tabindex="-1"
                label="Long name"
                prepend-icon="mdi-identifier"
                dense
                readonly
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="comboStops"
                tabindex="6"
                :items="BonusLocationsRidingNamesOnlyGetter"
                label="Bonus stops in this combo"
                persistent-hint
                hide-selected
                deletable-chips
                clearable
                multiple
                small-chips
                auto-select-first
                @change="updateForm"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            tabindex="8"
            text
            color="grey"
            class="mb-5"
            elevation="0"
            @click="close()"
            >Cancel</v-btn
          >
          <v-btn
            tabindex="7"
            :disabled="
              !(
                comboStops &&
                newComboBonus.name &&
                newComboBonus.points &&
                comboStops.length >= 2
              )
            "
            :loading="loadingSaveButton"
            color="green"
            class="mr-5 mb-5 white--text"
            elevation="0"
            @click="saveComboBonus()"
            >Save combo</v-btn
          >
        </v-card-actions>
      </v-form></v-card
    >
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as inputValidationRules from '@/components/inputValidationRules'
import * as geneticAlgorithmFunction from '@/components/geneticAlgorithm'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations.js'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'ShowAddCombo',
  props: {
    value: { required: true, type: Boolean }
  },
  data() {
    const data = {
      loadingSaveButton: false,
      newComboBonus: new BonusLocationsPrototype(),
      isValid: false,
      comboStops: null
    }
    return { ...data, ...inputValidationRules, ...geneticAlgorithmFunction }
  },
  computed: {
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsRidingNamesOnlyGetter'
    ])
  },
  created: function () {
    this.newComboBonus = new BonusLocationsPrototype()
  },
  methods: {
    ...mapActions('moduleBonusLocations', ['BonusLocationsCreateUpdateAction']),
    updateForm() {
      if (this.comboStops && this.comboStops.length > 0) {
        this.newComboBonus.combostops = this.comboStops.join()
        this.comboStops.sort((a, b) => (a > b ? 1 : -1))
      } else {
        this.newComboBonus.combostops = null
      }
      this.newComboBonus.bonusid = uuidv4()
      this.newComboBonus.setCombototalpoints()
      this.newComboBonus.setValue()
      this.newComboBonus.setCombocategory()
      this.newComboBonus.setLongname()
    },
    close() {
      this.$emit('input', !this.value)
    },
    async saveComboBonus() {
      this.loadingSaveButton = true
      this.newComboBonus.comment = this.newComboBonus.description
      let geneticOutput = await this.geneticAlgorithm(this.comboStops)
      this.newComboBonus.combostops = geneticOutput.route
      this.newComboBonus.bestroutelength = geneticOutput.length
      await this.BonusLocationsCreateUpdateAction(this.newComboBonus)
      this.loadingSaveButton = false
      this.newComboBonus = new BonusLocationsPrototype()
      this.comboStops = null
      this.close()
    }
  }
}
</script>

<style></style>
