import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,{staticClass:"pl-4"},[_vm._v("Combo bonus location details")]),_c(VSpacer)],1),_c(VCardText,{staticClass:"pt-5"},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"500px"},attrs:{"options":_vm.mapOptions,"center":_vm.mapCenter,"zoom":_vm.mapZoom}},_vm._l((_vm.mapMarkers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false,"icon":m.icon}})}),1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("Dismiss")]),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }