import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000px"},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"elevation":"2"}},[_c(VForm,{ref:"formCombo",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Add combo bonus")])],1),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"tabindex":"1","autofocus":"","label":"Combo name","prepend-icon":"mdi-group","dense":"","rules":[
                _vm.validateRequired(_vm.newComboBonus.name),
                _vm.validateHTML(_vm.newComboBonus.name),
                _vm.validateLength(_vm.newComboBonus.name, 250),
                _vm.validateCharacters(_vm.newComboBonus.name)
              ]},on:{"change":_vm.updateForm},model:{value:(_vm.newComboBonus.name),callback:function ($$v) {_vm.$set(_vm.newComboBonus, "name", $$v)},expression:"newComboBonus.name"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"tabindex":"1","label":"Combo value","type":"number","dense":"","prepend-icon":"mdi-counter","rules":[
                _vm.validateRequired(_vm.newComboBonus.points),
                _vm.validateMinimum(_vm.newComboBonus.points, 0),
                _vm.validateMaximum(_vm.newComboBonus.points, 250000)
              ]},on:{"change":_vm.updateForm},model:{value:(_vm.newComboBonus.points),callback:function ($$v) {_vm.$set(_vm.newComboBonus, "points", $$v)},expression:"newComboBonus.points"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"tabindex":"-1","label":"Total point value","type":"number","dense":"","readonly":"","prepend-icon":"mdi-sigma"},model:{value:(_vm.newComboBonus.combototalpoints),callback:function ($$v) {_vm.$set(_vm.newComboBonus, "combototalpoints", $$v)},expression:"newComboBonus.combototalpoints"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"tabindex":"-1","label":"Combo category","type":"text","dense":"","readonly":"","prepend-icon":"mdi-shape"},model:{value:(_vm.newComboBonus.category),callback:function ($$v) {_vm.$set(_vm.newComboBonus, "category", $$v)},expression:"newComboBonus.category"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"tabindex":"4","label":"Description","prepend-icon":"mdi-subtitles-outline","dense":"","rules":[
                _vm.validateHTML(_vm.newComboBonus.description),
                _vm.validateLength(_vm.newComboBonus.description, 1000),
                _vm.validateCharacters(_vm.newComboBonus.description)
              ]},model:{value:(_vm.newComboBonus.description),callback:function ($$v) {_vm.$set(_vm.newComboBonus, "description", $$v)},expression:"newComboBonus.description"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"dense":"","cols":"6"}},[_c(VTextField,{attrs:{"tabindex":"5","label":"Rallybook page","type":"number","dense":"","rules":[
                _vm.validateWholeNumber(_vm.newComboBonus.page),
                _vm.validateMinimum(_vm.newComboBonus.page, 0),
                _vm.validateMaximum(_vm.newComboBonus.page, 1000)
              ]},on:{"change":_vm.updateForm},model:{value:(_vm.newComboBonus.page),callback:function ($$v) {_vm.$set(_vm.newComboBonus, "page", $$v)},expression:"newComboBonus.page"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"tabindex":"-1","label":"Long name","prepend-icon":"mdi-identifier","dense":"","readonly":""},model:{value:(_vm.newComboBonus.longname),callback:function ($$v) {_vm.$set(_vm.newComboBonus, "longname", $$v)},expression:"newComboBonus.longname"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"tabindex":"6","items":_vm.BonusLocationsRidingNamesOnlyGetter,"label":"Bonus stops in this combo","persistent-hint":"","hide-selected":"","deletable-chips":"","clearable":"","multiple":"","small-chips":"","auto-select-first":""},on:{"change":_vm.updateForm},model:{value:(_vm.comboStops),callback:function ($$v) {_vm.comboStops=$$v},expression:"comboStops"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"mb-5",attrs:{"tabindex":"8","text":"","color":"grey","elevation":"0"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"mr-5 mb-5 white--text",attrs:{"tabindex":"7","disabled":!(
              _vm.comboStops &&
              _vm.newComboBonus.name &&
              _vm.newComboBonus.points &&
              _vm.comboStops.length >= 2
            ),"loading":_vm.loadingSaveButton,"color":"green","elevation":"0"},on:{"click":function($event){return _vm.saveComboBonus()}}},[_vm._v("Save combo")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }